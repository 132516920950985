import React from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import ServiceCard from './ServiceCard';

const OurServices = () => {
  const services = [
    {
      title: 'Unlimited Support Tickets',
      description: '24/7 fast support to ensure continuous software updates and troubleshooting.',
      icon: 'support_agent',
    },
    {
      title: 'Rapid Development',
      description: 'Accelerate development cycles to meet crucial timelines and project demands.',
      icon: 'rocket_launch',
    },
    {
      title: 'Secure',
      description: 'Implement robust security measures to safeguard your data and operational integrity.',
      icon: 'security',
    },
    {
      title: 'Data Integration',
      description: 'Seamlessly integrate your existing systems and data with new software solutions.',
      icon: 'integration_instructions',
    },
    {
      title: 'Cost Efficiency',
      description: 'Pay only for what you need, optimize resources, and scale alongside your business',
      icon: 'savings',
    },
  ];

  return (
    <Box sx={{ width: '100%', py: 8 }}>
      <Container 
        maxWidth="lg" 
        className="withPadding"
      >
        <Typography variant="h2" component="h2" align="center" gutterBottom>
          Our Services
        </Typography>
        <Typography variant="h5" component="p" align="center" sx={{ mb: 6 }}>
          Innovative AI solutions tailored to your needs
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          {services.map((service, index) => (
            <Grid 
              item 
              xs={12} 
              sm={6} 
              md={4}
              key={index}
            >
              <ServiceCard 
                {...service} 
                sx={{ 
                  height: '100%',
                  maxWidth: '100%',
                }} 
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default OurServices;