import React, { useState } from 'react';
import { Box, Container, Typography, Grid, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ApproachCard from './ApproachCard';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import SpeedIcon from '@mui/icons-material/Speed';
import SecurityIcon from '@mui/icons-material/Security';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

const approachData = [
  {
    title: 'Book a Call',
    subtitle: 'Start Your Journey',
    description: 'Schedule a consultation to discuss your specific needs and how our solutions can benefit your organization.',
    icon: CalendarTodayIcon,
    details: ['Free initial consultation', 'Discuss your needs', 'Explore potential solutions'],
  },
  {
    title: 'Real-Time Generation',
    subtitle: 'See It in Action',
    description: 'Watch as we generate tailored solutions in real-time, adapting to your unique requirements and preferences.',
    icon: SpeedIcon,
    details: ['Live demo', 'Customized solutions', 'Instant feedback'],
  },
  {
    title: 'Security & Requirements',
    subtitle: 'Ensure Perfect Fit',
    description: 'Give us a week to bug test and ensure a secure and compliant solution that fits your organization.',
    icon: SecurityIcon,
    details: ['Security assessment', 'Compliance check', 'Requirement refinement'],
  },
  {
    title: 'Implementation',
    subtitle: 'Go Live',
    description: 'Start using the custom-built software in your organization, with our full support for a smooth integration and updates.',
    icon: RocketLaunchIcon,
    details: ['Seamless integration', 'Staff training', 'Ongoing support'],
  },
];

const CuttingEdgeApproach = () => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate('/contact');
    window.scrollTo(0, 0);
  };

  return (
    <Box sx={{ 
      py: { xs: 6, sm: 8, md: 10 }, 
      color: 'black',
    }}>
      <Container maxWidth="xl">
        <Typography
          variant="h2"
          component="h2"
          align="center"
          sx={{
            mb: { xs: 2, sm: 3 },
            fontWeight: 'bold',
            color: 'primary.main',
            fontSize: { xs: '2.5rem', sm: '3rem', md: '3.5rem' },
          }}
        >
          Simplifying Your Journey
        </Typography>
        <Typography
          variant="h4"
          component="h3"
          align="center"
          sx={{
            mb: { xs: 3, sm: 4, md: 5 },
            color: 'text.secondary',
            fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' },
            fontWeight: 'normal',
          }}
        >
          Four Easy Steps to Transform Your Business
        </Typography>

        <Grid 
          container 
          spacing={{ xs: 2, sm: 3, md: 4 }}
          sx={{ 
            justifyContent: 'center',
            px: { xs: 2, sm: 3, md: 4 },
          }}
        >
          {approachData.map((item, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <Typography
                  variant="h4"
                  className="number-hover"
                  onMouseEnter={() => setHoveredItem(index)}
                  onMouseLeave={() => setHoveredItem(null)}
                  sx={{
                    mb: 2,
                    color: 'white',
                    fontWeight: 'bold',
                    position: 'relative',
                    display: 'inline-block',
                    width: '60px',
                    height: '60px',
                    lineHeight: '60px',
                    textAlign: 'center',
                    borderRadius: '50%',
                    backgroundColor: 'primary.main',
                    transition: 'transform 0.3s, box-shadow 0.3s',
                    cursor: 'pointer',
                    transform: hoveredItem === index ? 'translateY(-8px)' : 'none',
                    boxShadow: hoveredItem === index ? 3 : 0,
                  }}
                >
                  {index + 1}
                </Typography>
                <ApproachCard 
                  {...item} 
                  index={index + 1}
                  onMouseEnter={() => setHoveredItem(index)}
                  onMouseLeave={() => setHoveredItem(null)}
                  isHovered={hoveredItem === index}
                />
              </Box>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 8, position: 'relative' }}>
          <Button
            variant="contained"
            size="large"
            onClick={handleGetStarted}
            sx={{
              fontSize: { xs: '1.4rem', sm: '1.6rem', md: '1.8rem' },
              padding: { xs: '16px 32px', sm: '18px 36px', md: '20px 40px' },
              borderRadius: '50px',
              textTransform: 'none',
              backgroundColor: 'black',
              color: 'white',
              fontWeight: 'bold',
              position: 'relative',
              overflow: 'hidden',
              transition: 'all 0.3s ease',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              '&:hover': {
                transform: 'translateY(-4px) scale(1.05)',
                backgroundColor: 'black',
                boxShadow: '0 6px 8px rgba(0, 0, 0, 0.2)',
              },
              '&:active': {
                transform: 'translateY(-2px) scale(1.02)',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              },
              '&::before': {
                content: '""',
                position: 'absolute',
                top: '-50%',
                left: '-50%',
                width: '200%',
                height: '200%',
                backgroundColor: 'rgba(255,255,255,0.1)',
                transform: 'rotate(45deg)',
                transition: 'all 0.3s ease',
              },
              '&:hover::before': {
                left: '-100%',
                top: '-100%',
              },
            }}
          >
            Build Your Custom Solution
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default CuttingEdgeApproach;
