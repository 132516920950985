import React from 'react';
import { Box, Container, Typography, Grid, Button } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import ReactTypingEffect from 'react-typing-effect';
import demoImg from '../imgs/demo5.jpg';

const HeroSection = ({ scrollToCuttingEdge }) => {
  const typingTexts = [
    'Build exactly what you need.',
    'Don\'t be limited by off-the-shelf \nsoftware.',
    'Adapts with your business,\nnot the other way around.',
  ];

  return (
    <Box
      sx={{
        pt: { xs: 20, md: 24 }, // Reduced top padding
        pb: { xs: 8, md: 10 }, // Slightly reduced bottom padding
        overflow: 'hidden',
        backgroundColor: '#111', // Slightly lighter than pure black
      }}
    >
      <Container maxWidth="xl" sx={{ px: { xs: 3, sm: 5, md: 8 } }}>
        <Box sx={{ maxWidth: '1400px', mx: 'auto', px: { xs: 2, sm: 3, md: 4 } }}>
          <Grid container spacing={6} alignItems="center" justifyContent="space-between">
            <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
              <Box sx={{ maxWidth: { xs: '100%', md: '95%' } }}>
                <Typography
                  component="h1"
                  gutterBottom
                  sx={{
                    fontWeight: 900,
                    mb: { xs: 4, sm: 5, md: 6 },
                    fontSize: { xs: '2.5rem', sm: '3.5rem', md: '4.5rem', lg: '5rem' },
                    lineHeight: 1.1,
                    letterSpacing: '-0.03em',
                    color: '#f0f0f0', // Very light gray, close to white
                    textShadow: '0 0 10px rgba(255,255,255,0.1)',
                    fontFamily: '"Poppins", sans-serif',
                    maxWidth: '100%',
                    background: 'linear-gradient(45deg, #f0f0f0, #d0d0d0)', // Light gray gradient
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  Custom Enterprise Solution for Your Unique Needs
                </Typography>
                <Box
                  sx={{
                    mb: { xs: 2, sm: 3, md: 4 }, // Reduced margin bottom
                    height: { xs: '6rem', sm: '6.5rem', md: '7.25rem' }, // Kept the increased height
                  }}
                >
                  <ReactTypingEffect
                    text={typingTexts}
                    speed={40}
                    eraseSpeed={20}
                    eraseDelay={1000}
                    typingDelay={100}
                    cursorRenderer={cursor => <span>{cursor}</span>}
                    displayTextRenderer={(text) => (
                      <Typography
                        sx={{
                          fontSize: { xs: '1.25rem', sm: '1.5rem', md: '1.75rem' },
                          lineHeight: '1.5', // Increased line height
                          fontWeight: 400,
                          color: 'white',
                          whiteSpace: 'pre-line', // Changed from 'nowrap' to 'pre-line'
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          verticalAlign: 'middle',
                          display: 'inline-flex',
                        }}
                      >
                        {text}
                      </Typography>
                    )}
                  />
                </Box>
                <Button
                  variant="contained"
                  size="large"
                  onClick={scrollToCuttingEdge}
                  endIcon={<ArrowForward />}
                  sx={{
                    py: { xs: 1.5, sm: 2 },
                    px: { xs: 4, sm: 6 },
                    fontSize: { xs: '1rem', sm: '1.25rem' },
                    fontWeight: 600,
                    borderRadius: '50px',
                    textTransform: 'none',
                    color: '#000',
                    backgroundColor: '#fff',
                    '&:hover': {
                      backgroundColor: '#e0e0e0',
                    },
                    transition: 'all 0.3s ease',
                    mt: { xs: 1, sm: 2, md: 3 }, // Reduced margin top
                  }}
                >
                  Get Started
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
              <Box
                sx={{
                  height: { xs: 450, sm: 550, md: 650 },
                  borderRadius: '30px',
                  overflow: 'hidden',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  boxShadow: '0 0 20px rgba(255, 255, 255, 0.1)', // Reduced shadow intensity
                  position: 'relative',
                  transform: 'perspective(1000px) rotateY(-15deg)',
                  transition: 'transform 0.5s ease',
                  '&:hover': {
                    transform: 'perspective(1000px) rotateY(-5deg) translateZ(30px)',
                  },
                  outline: 'none',
                }}
              >
                <Box
                  component="img"
                  src={demoImg}
                  alt="Enterprise Software Illustration"
                  loading="lazy"
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    userSelect: 'none',
                    pointerEvents: 'none',
                    // Removed the grayscale filter
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'linear-gradient(135deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.3) 100%)', // Adjusted gradient
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default HeroSection;