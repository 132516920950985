import React from 'react';
import { Box, Container, Typography, IconButton, SvgIcon, Link } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';

// Custom X (formerly Twitter) icon
const XIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
  </SvgIcon>
);

const Footer = () => {
  const gmailComposeUrl = "https://mail.google.com/mail/?view=cm&fs=1&to=connectblancai@gmail.com";

  return (
    <Box
      component="footer"
      sx={{
        bgcolor: 'black',
        py: 2,
        borderTop: '1px solid',
        borderColor: 'rgba(255, 255, 255, 0.1)',
        width: '100%',
        userSelect: 'none', // Prevent text selection
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
            userSelect: 'none', // Apply the non-selectable style to this section as well
          }}
        >
          {/* Social Icons */}
          <IconButton aria-label="X (formerly Twitter)" sx={{ color: 'white' }}>
            <XIcon />
          </IconButton>
          <IconButton aria-label="LinkedIn" sx={{ color: 'white' }}>
            <LinkedInIcon />
          </IconButton>

          {/* Email Contact */}
          <Link href={gmailComposeUrl} target="_blank" rel="noopener noreferrer" underline="none">
            <IconButton aria-label="Email" sx={{ color: 'white' }}>
              <EmailIcon />
            </IconButton>
          </Link>

          {/* Divider Line */}
          <Box
            component="span"
            sx={{
              height: '100%', // Ensure it takes full height
              minHeight: '24px', // Ensure minimum height for visibility
              width: '1px',
              bgcolor: 'rgba(255, 255, 255, 0.8)', // Increase opacity to ensure visibility
              mx: 2,
              userSelect: 'none', // Ensure the divider isn't selectable
            }}
          ></Box>

          {/* Copyright Text */}
          <Typography
            variant="body2"
            color="white"
            sx={{ userSelect: 'none' }} // Ensure the copyright text isn't selectable
          >
            © {new Date().getFullYear()} Blanc. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
