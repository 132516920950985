import React from 'react';
import './QuoteSection.css';

const QuoteSection = () => {
  return (
    <div className="quote-section" aria-label="Quotes about data quality and model performance">
      <div className="quote-container">
        <div className="main-quote">
          <div className="rectangular-quote top-quote">
            <p>"Every business is unique. Why settle for a one-size-fits-all solution when you can have software designed specifically for your needs?"</p>
          </div>
          <h1>Your Business Deserves More Than Off-the-Shelf</h1>
          <h2> Custom Software Delivers Exactly What You Need, When You Need It — No Compromises, No Limits.</h2>
          <div className="rectangular-quote bottom-quote">
            <p>"Off-the-shelf software often means paying for features you don't need and missing out on the ones you do."</p>
          </div>
        </div>
        <div className="side-quotes-container">
          {[
            {
              text: `"Your business tools can advance at the pace of your ideas, not the limitations of out-of-the-box solutions"`,
              size: 'medium'
            },
            {
              text: `"The right tools can redefine what's possible to unlock new efficiencies and drive your business forward""`,
              size: 'large'
            },
            {
              text: `"Every feature and function within your software serves a purpose aligned directly with your business goals"`,
              size: 'small'
            },
            {
              text: `"You are not just buying a product; you're investing in a solution that grows and adapts with your business."`,
              size: 'medium'
            },
          ].map((quote, index) => (
            <div className={`side-quote side-quote-${quote.size}`} key={index}>
              <p>{quote.text}</p>
              <span className="quote-author">{quote.author}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QuoteSection;
