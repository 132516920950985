import React, { useEffect } from 'react';
import { Box, Container, Typography } from '@mui/material';
import Header from './Header';

const Contact = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);

    /* global Cal */
    (function (C, A, L) {
      let p = function (a, ar) {
        a.q.push(ar);
      };
      let d = C.document;
      C.Cal =
        C.Cal ||
        function () {
          let cal = C.Cal;
          let ar = arguments;
          if (!cal.loaded) {
            cal.ns = {};
            cal.q = cal.q || [];
            d.head.appendChild(d.createElement('script')).src = A;
            cal.loaded = true;
          }
          if (ar[0] === L) {
            const api = function () {
              p(api, arguments);
            };
            const namespace = ar[1];
            api.q = api.q || [];
            typeof namespace === 'string'
              ? (cal.ns[namespace] = api) && p(api, ar)
              : p(cal, ar);
            return;
          }
          p(cal, ar);
        };
    })(window, 'https://app.cal.com/embed/embed.js', 'init');

    Cal('init', { origin: 'https://app.cal.com' });
    Cal('inline', {
      elementOrSelector: '#my-cal-inline',
      calLink: 'blanc-ai-rj8zvy/30min',
      config: {
        theme: 'light',
        hideEventTypeDetails: false,
        hideLandingPageDetails: false,
        initialDate: '2024-09-06',
      }
    });
  }, []);

  return (
    <Box sx={{ 
      py: 10, 
      backgroundColor: '#f5f7fa',
      minHeight: '100vh', // Ensure the box takes at least the full viewport height
    }}>
      <Header />
      <Container 
        maxWidth="md" 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'center',
          alignItems: 'center',
          flex: '1 0 auto', 
          mt: 3, // Add top margin to account for the fixed header
        }}
      >
        <Typography variant="h2" component="h1" gutterBottom sx={{ mb: 2.5, textAlign: 'center' }}>
          Book a Meeting
        </Typography>

        <Box 
          id="my-cal-inline"
          sx={{ 
            width: '100%',
            height: 'calc(100vh - 64px - 64px - 32px)',
            borderRadius: 2,
            mt: 2,
          }}
        />
      </Container>
    </Box>
  );
};

export default Contact;