import React, { useRef, memo } from 'react';
import { Box } from '@mui/material';
import Header from './Header';
import CuttingEdgeApproach from './LastBottomCards/CuttingEdgeApproach';
import HeroSection from './HeroSection';
import OurServices from './OurServices/OurServices';
import QuoteSection from './QuoteSection/QuoteSection';

const LandingPage = () => {
  const cuttingEdgeRef = useRef(null);

  const scrollToCuttingEdge = () => {
    cuttingEdgeRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Box sx={{ 
      width: '100%', 
      overflowX: 'hidden', 
      color: 'black',
      fontFamily: '"Inter", sans-serif',
      userSelect: 'none',
      minHeight: '100vh',
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'linear-gradient(135deg, #f5f7fa, #e8eef5, #dfe6f0, #e8eef5)',
        backgroundSize: '400% 400%',
        animation: 'gradientAnimation 15s ease infinite',
        zIndex: -1,
      },
      '@keyframes gradientAnimation': {
        '0%': { backgroundPosition: '0% 50%' },
        '50%': { backgroundPosition: '100% 50%' },
        '100%': { backgroundPosition: '0% 50%' },
      },
    }}>
      <Header />

      {/* Hero Section */}
      <HeroSection scrollToCuttingEdge={scrollToCuttingEdge} />

      {/* Our Services Section */}
      <OurServices />

      {/* Quote Section */}
      <QuoteSection />  

      {/* Cutting Edge Approach Section */}
      <Box ref={cuttingEdgeRef}>
        <CuttingEdgeApproach />
      </Box>
    </Box>
  );
};

export default memo(LandingPage);
