import React from 'react';
import { AppBar, Toolbar, Button, Box, IconButton, useTheme, useMediaQuery } from '@mui/material';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email'; // Import the EmailIcon
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'; // Import the CalendarTodayIcon
import logo from '../imgs/logo.png';

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
  };

  const isContactPage = location.pathname === '/contact';

  const buttonStyle = {
    fontSize: isMobile ? '0.875rem' : '1rem',
    py: isMobile ? 1 : 1.5,
    px: isMobile ? 2 : 3,
    fontWeight: 600,
    textTransform: 'none',
    backgroundColor: '#000',
    color: '#fff',
    borderRadius: '50px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: '#333',
      boxShadow: '0 6px 8px rgba(0, 0, 0, 0.2)',
      transform: 'translateY(-2px)',
    },
  };

  const handleContactClick = () => {
    const email = 'connectblancai@gmail.com';
    if (isMobile) {
      // Mobile behavior: open default email app
      const mailtoLink = `mailto:${encodeURIComponent(email)}`;
      window.location.href = mailtoLink;
    } else {
      // Desktop behavior: open Gmail directly
      const gmailUrl = `https://mail.google.com/mail/u/0/?fs=1&to=${encodeURIComponent(email)}&tf=cm`;
      window.open(gmailUrl, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <AppBar
      position="fixed"
      elevation={4}
      sx={{
        background: '#ffffff', // Solid white background
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        width: '100%',
        height: 'auto',
        top: 0,
        left: 0,
        right: 0,
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar sx={{ py: 1 }}> {/* Added vertical padding */}
        <Box sx={{ flexGrow: 1 }}>
          <IconButton onClick={handleLogoClick} sx={{ p: 0 }}>
            <img src={logo} alt="Company Logo" style={{ height: '30px', width: 'auto' }} />
          </IconButton>
        </Box>
        {!isContactPage && (
          <Button 
            color="primary" 
            variant="contained" 
            component={RouterLink} 
            to="/contact"
            startIcon={<CalendarTodayIcon />} // Add the CalendarTodayIcon here
            sx={buttonStyle}
          >
            Book Now
          </Button>
        )}
        {isContactPage && (
          <Button 
            variant="contained" 
            onClick={handleContactClick}
            startIcon={<EmailIcon />} // Add the EmailIcon here
            sx={buttonStyle}
          >
            Contact Us
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;