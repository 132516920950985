import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { SupportAgent, RocketLaunch, Security, IntegrationInstructions, Savings, Lightbulb } from '@mui/icons-material';

const iconMap = {
  support_agent: SupportAgent,
  rocket_launch: RocketLaunch,
  security: Security,
  integration_instructions: IntegrationInstructions,
  savings: Savings,
};

const ServiceCard = ({ title, description, icon }) => {
  const IconComponent = iconMap[icon] || Lightbulb;

  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', borderRadius: 4, boxShadow: 3 }}>
      <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
          <IconComponent sx={{ fontSize: 48, color: 'primary.main', mb: 2 }} />
          <Typography 
            gutterBottom 
            variant="h5" 
            component="h3" 
            align="center" 
            sx={{ 
              fontWeight: 'bold',
              minHeight: '3em', // Add this line
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {title}
          </Typography>
        </Box>
        <Typography variant="body1" color="text.secondary" align="center">
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ServiceCard;