import React from 'react';
import { Card, CardContent, Typography, Box, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const ApproachCard = ({ title, subtitle, description, icon: Icon, details = [], index, onMouseEnter, onMouseLeave, isHovered }) => {
  return (
    <Card 
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      sx={{ 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column', 
        borderRadius: 10,
        boxShadow: isHovered ? 6 : 3,
        transition: 'transform 0.3s, box-shadow 0.3s',
        transform: isHovered ? 'translateY(-8px)' : 'none',
      }}
    >
      <Box sx={{ bgcolor: 'primary.main', p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Icon sx={{ fontSize: 80, color: 'white' }} />
      </Box>
      <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h5" component="div" gutterBottom align="center" sx={{ fontWeight: 'bold', mb: 2 }}>
          {title}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" gutterBottom align="center" sx={{ mb: 2 }}>
          {subtitle}
        </Typography>
        <Typography variant="body2" color="text.secondary" align="center" sx={{ mb: 3 }}>
          {description}
        </Typography>
        {details.length > 0 && (
          <>
            <Divider sx={{ my: 2 }} />
            <List dense>
              {details.map((detail, idx) => (
                <ListItem key={idx}>
                  <ListItemIcon>
                    <Box
                      sx={{
                        width: 20,
                        height: 20,
                        borderRadius: '50%',
                        bgcolor: 'black',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <CheckIcon sx={{ color: 'white', fontSize: 16 }} />
                    </Box>
                  </ListItemIcon>
                  <ListItemText primary={detail} />
                </ListItem>
              ))}
            </List>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default ApproachCard;